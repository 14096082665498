import {Button, Image} from 'react-bootstrap'
import stairs from '../../assets/images/stairs.png';
import eye from '../../assets/images/eye.png';

const Navigation = ({socket, routes, isActionsBlocked, handleBlockActions, handleScout}) => {
  const onRouteClick = (route) => {
    handleBlockActions()
    socket.emit('move', {dir: route})
  }

  return (
    <>
      {routes.map((route) => (
        <div key={route} className={'route-button route-' + route}>
          <Button
            variant="light"
            type="button"
            size="sm"
            className="m-1"
            onClick={() => onRouteClick(route)}
            disabled={isActionsBlocked}
            style={route === 'up' || route === 'down' ? {
              backgroundImage: `url(${stairs})`,
            } : {}}
          >
            {route === 'up' || route === 'down' ? '' : routeToArrow(route)}
          </Button>
          {route !== 'up' && route !== 'down' && <Button
            variant="light"
            type="button"
            size="sm"
            className="m-1"
            onClick={() => handleScout(route)}
          >
            <Image src={eye} alt="" rounded/>
          </Button>}
        </div>
      ))}
    </>
  )
}

const routeToArrow = (route) => {
  if (route === 'north') {
    return <>&uarr;</>
  } else if (route === 'west') {
    return <>&larr;</>
  } else if (route === 'east') {
    return <>&rarr;</>
  } else if (route === 'south') {
    return <>&darr;</>
  } else if (route === 'up') {
    return <>Up</>
  } else if (route === 'down') {
    return <>Down</>
  }
}

export default Navigation
