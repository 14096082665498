import {Badge} from 'react-bootstrap'

const Messages = ({messages = []}) => {
  return <>
    {messages.map((msg, i) => <Badge
      key={i}
      bg={msg.type}
      style={{...styles.badge, top: (100 + i * 28)}}
    >
      {msg.text}
    </Badge>)}
  </>
}

const styles = {
  badge: {
    position: 'absolute',
    top: 100,
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 10,
    opacity: 0.7,
    transition: 'top 0.2s linear',
  }
}

export default Messages
