const statToText = (stat) => Array.isArray(stat)
  ? `${Number.parseFloat((stat[0] / 100).toFixed(2))}-${Number.parseFloat((stat[1] / 100).toFixed(2))}`
  : Number.parseFloat((stat / 100).toFixed(2))

const getUnits = (stat) => {
  let unit = ''
  switch (stat) {
    case 'critical': // ---- common
    case 'spell critical':
    case 'dodge':
    case 'parry':
    case 'block chance':
    case 'magic resistance':
    case 'accuracy': // ---- advanced
    case 'attack speed':
    case 'casting speed':
    case 'damage bonus':
    case 'spell damage bonus':
    case 'magic find':
    case 'pet damage bonus':
    case 'earth resistance':
    case 'fire resistance':
    case 'water resistance':
    case 'air resistance':
    case 'sword damage': // ---- expert
    case 'axe damage':
    case 'dagger damage':
    case 'polearm damage':
    case 'mace damage':
    case 'earth spell damage':
    case 'fire spell damage':
    case 'water spell damage':
    case 'air spell damage':
    case 'damage reduction': // ---- ultimate
    case 'spell penetration':
    case 'armor penetration':
      unit = '%'
      break

    case 'hp regeneration':
    case 'mana regeneration':
      unit = ' / 3s'
      break

    default:
      unit = ''
  }
  return unit
}

const getPrefix = (stat) => {
  let prefix = ''
  switch (stat) {
    case 'max hp':
    case 'max mana':
    case 'skill bonus':
      prefix = '+'
      break

    default:
      prefix = ''
  }
  return prefix
}

const statsToIgnore = ['max hp', 'max mana']

const getPrimaryPercent = (character, stat) => {
  const current = character.stats.primary[stat] ?? 0
  const max = (stat === 'shield' ? 2500 : character.stats.secondary['max ' + stat])
  return 100 * current / max
}

const getPrimaryText = (character, stat) => {
  const current = Math.floor(character.stats.primary[stat] / 100)
  const max = Math.floor(character.stats.secondary['max ' + stat] / 100)
  return `${current} / ${max}`
}

const getExperiencePercent = (level, experience) => {
  return experience / (Math.pow(1.45, level - 1))
}

const getExperienceText = (level, experience) => {
  return `${experience} / ${Math.floor(100 * Math.pow(1.45, level - 1))}`
}

export {
  statToText,
  getUnits,
  getPrefix,
  statsToIgnore,
  getPrimaryPercent,
  getPrimaryText,
  getExperiencePercent,
  getExperienceText
}
