import {Button, ProgressBar} from 'react-bootstrap'
import {getPrimaryPercent, getPrimaryText} from '../../utils/stats'
import {raceAssets} from '../../utils/assets'

const Character = ({character, isSelected, isAggressive, isInCombat, setTarget, moveAnimation, actionResults = [], isAllies}) => {
  return <Button
    className={'my-1 mx-2 p-0' + (moveAnimation ? ' move-' + moveAnimation : '')}
    variant={isAggressive ? 'danger' : 'secondary'}
    type="button"
    onClick={setTarget}
    style={{
      ...styles.character,
      border: isSelected ? '2px solid rgb(101, 241, 255)' : '2px solid transparent',
    }}
  >
    {/* ========= Shield ========= */}
    {character.stats.primary.shield > 0 && <ProgressBar
      now={getPrimaryPercent(character, 'shield')}
      label="shield"
      variant="warning"
      style={styles.progress}
    />}

    {/* ========= HP ========= */}
    <ProgressBar
      now={getPrimaryPercent(character, 'hp')}
      label={getPrimaryText(character, 'hp')}
      variant="success"
      className="mb-1"
      style={styles.progress}
    />

    {/* ========= Race Class Name ========= */}
    <div className="mb-1" style={styles.characterTitle}>
      {character.race || ''} {character.gameClass || ''}<br/>
      <strong style={styles.characterName}>{character.name}</strong>
    </div>

    {/* ========= Race image ========= */}
    <div style={{
      ...styles.characterBody,
      backgroundImage: `url(${raceAssets[(character.race)]})`,
    }}></div>

    {/* ========= Mana ========= */}
    <ProgressBar
      now={getPrimaryPercent(character, 'mana')}
      label={getPrimaryText(character, 'mana')}
      style={styles.progress}
    />

    {/* ========= Float up info ========= */}
    {actionResults.map((d) => <div
      key={`char-${d.key}`}
      className={`damage hit-${d.hitType}`}
      style={{
        left: (d.hitType === 'ability' || d.hitType === 'skill') ? (isAllies ? '105%' : 'unset') : d.left,
        right: ((d.hitType === 'ability' || d.hitType === 'skill') && !isAllies) ? '105%' : 'unset',
        animationDelay: d.delay,
      }}>
      {d.value}
    </div>)}

    {/* ========= Current combat target ========= */}
    {isInCombat && <div style={styles.inCombat}>{isInCombat}</div>}
  </Button>
}

const styles = {
  character: {
    width: 70,
    fontSize: '9px',
    textAlign: 'center',
    position: 'relative',
    display: 'inline-block',
  },
  characterTitle: {
    lineHeight: '10px',
    wordWrap: 'break-word',
  },
  characterBody: {
    height: 64,
    backgroundSize: 'cover',
    backgroundPositionX: 'center',
  },
  characterName: {
    fontSize: '13px',
  },
  progress: {
    fontSize: '8px',
    height: '0.5rem',
    color: '#000',
  },
  inCombat: {
    position: 'absolute',
    top: -18,
    left: '50%',
    width: '100%',
    height: 20,
    transform: 'translateX(-50%)',
    fontWeight: 'bold',
  },
}

export default Character
