import {useEffect, useState} from 'react'
import logger from '../utils/logger'
import {Accordion, Col, ProgressBar, Row} from 'react-bootstrap'
import {getExperiencePercent, getExperienceText} from '../utils/stats'
import '../assets/css/style.css'
import bg from '../assets/images/bg-dungeon.png'
import Fade from './game/Fade'
import Stats from './game/Stats'
import Skills from './game/Skills'
import Equipment from './game/Equipment'
import Inventory from './game/Inventory'
import ItemModal from './game/ItemModal'
import Navigation from './game/Navigation'
import Character from './game/Character'
import Item from './game/Item'
import Abilities from './game/Abilities'
import Messages from './game/Messages'
import ScoutModal from './game/ScoutModal'

const GLOBAL_COOLDOWN = 2000

const Game = ({socket, gameState, characterId}) => {
  const [showScoutModal, setShowScoutModal] = useState(false)
  const [showItemModal, setShowItemModal] = useState(false)
  const [itemToShow, setItemToShow] = useState({item: {}, actions: {}, position: {}})
  const [currentTarget, setCurrentTarget] = useState({type: 'none', id: null})
  const [isActionsBlocked, setIsActionsBlocked] = useState(false)

  const [moveAnimations, setMoveAnimations] = useState({})
  const [messages, setMessages] = useState([])
  const [actionResults, setActionResults] = useState({item: {}, creature: {}})

  const handleShowItemModal = (item, actions, position) => {
    setShowItemModal(true)
    setItemToShow({item, actions, position})
    setCurrentTarget({type: 'item', id: item._id})
  }

  const handleHideItemModal = () => {
    setShowItemModal(false)
    setItemToShow({item: {}, actions: {}, position: {}})
  }

  const handleBlockActions = () => {
    setIsActionsBlocked(true)
    setTimeout(() => {
      setIsActionsBlocked(false)
    }, GLOBAL_COOLDOWN)
  }

  const handleScout = (dir) => {
    setShowScoutModal(true)
  }

  const handleHideScoutModal = () => {
    setShowScoutModal(false)
  }

  const {room, character} = findCharacterAndRoom(Object.values(gameState.map), characterId)

  useEffect(() => {
    socket.on('message', ({text, type = 'danger'}) => {
      logger.log('Game :: ON :: message')

      const newMessage = {
        id: Date.now(),
        type: type,
        text: text,
      }
      setMessages((prevMessages) => [...prevMessages, newMessage])

      setTimeout(() => {
        setMessages((prevMessages) =>
          prevMessages.filter((msg) => msg.id !== newMessage.id)
        )
      }, GLOBAL_COOLDOWN * 1.5)
    })

    socket.on('movement', ({id, dir}) => {
      setMoveAnimations((prevMoveAnimations) => {
        const newMoveAnimations = {...prevMoveAnimations}
        newMoveAnimations[id] = dir

        setTimeout(() => {
          setMoveAnimations((prevState) => {
            const updatedState = { ...prevState }
            delete updatedState[id]
            return updatedState
          })
        }, GLOBAL_COOLDOWN)

        return newMoveAnimations
      })
    })

    socket.on('actionResults', (actResults) => {
      setActionResults((prevActionResults) => {
        const updatedActionResults = {
          item: {...prevActionResults.item},
          creature: {...prevActionResults.creature},
        }

        const tmpDelayCounter = {}

        actResults.forEach((ar) => {
          const {targetType, id, hitType, value} = ar
          if (!updatedActionResults[targetType][id]) {
            updatedActionResults[targetType][id] = []
          }

          if (tmpDelayCounter[id] === undefined) {
            tmpDelayCounter[id] = 0
          } else {
            tmpDelayCounter[id]++
          }

          const newAnimation = {
            key: `${Date.now()}-${Math.random()}`,
            id,
            hitType,
            value,
            left: `${Math.floor(Math.random() * 40) + 25}%`,
            delay: `${tmpDelayCounter[id] * 300}ms`,
          }
          updatedActionResults[targetType][id] = [
            ...updatedActionResults[targetType][id],
            newAnimation,
          ]

          setTimeout(() => {
            setActionResults((prevState) => {
              const updatedState = {
                ...prevState,
                [targetType]: {
                  ...prevState[targetType],
                  [id]: prevState[targetType][id]?.filter((anim) => anim.key !== newAnimation.key)
                }
              }
              if (!updatedState[targetType][id]?.length) {
                delete updatedState[targetType][id]
              }
              return updatedState
            })
          }, 3000)
        })
        return updatedActionResults
      })
    })

    return () => {
      socket.off('message')
      socket.off('movement')
      socket.off('actionResults')
    }
  }, [])

  return (
    <>
      <Row className="vh-100">
        <Col lg={2} className="my-3 my-lg-4">
          <div className="text-center">
            {character.race || ''} {character.gameClass || ''} <strong>{character.name}</strong>
            <br/>
            <small>Level {character.level || 1}</small>
            <ProgressBar
              now={getExperiencePercent(character.level, character.stats.primary.experience)}
              label={getExperienceText(character.level, character.stats.primary.experience)}
              className="mb-4"
            />
          </div>

          <Accordion defaultActiveKey={['0']} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Stats</Accordion.Header>
              <Accordion.Body className="py-2">
                <Stats stats={character.stats}/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Skills</Accordion.Header>
              <Accordion.Body className="py-2">
                <Skills skills={character.spells} skillBonus={character.stats.secondary['skill bonus']}/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Equipment</Accordion.Header>
              <Accordion.Body className="py-2">
                <Equipment equipment={character.equipment} showItemModal={handleShowItemModal}/>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Inventory</Accordion.Header>
              <Accordion.Body className="py-2">
                <Inventory inventory={character.inventory} showItemModal={handleShowItemModal}/>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>

        <Col lg={8} className="my-3 my-lg-4">
          <h5 className="text-center">
            {room.name}
            {/*<Button variant="secondary" type="button" onClick={onLeaveWorld} className="mb-2 float-end">Leave World</Button>*/}
          </h5>
          <div className="d-flex justify-content-center">
            <div
              className={'game-area' + ((character.aggroList.length > 0 && (100 * character.stats.primary.hp / character.stats.secondary['max hp']) <= 20) ? ' almost-dead' : '')}
              style={styles.gameArea}
            >
              <Fade fadeOut={moveAnimations[character._id]}/>
              <div style={styles.gameBg}></div>

              <div style={styles.gameContent}>
                <Navigation
                  socket={socket}
                  routes={Object.keys(room.routes)}
                  isActionsBlocked={isActionsBlocked}
                  handleBlockActions={handleBlockActions}
                  handleScout={handleScout}
                />

                {currentTarget.id !== null &&
                  <Abilities
                    socket={socket}
                    target={currentTarget}
                    isActionsBlocked={isActionsBlocked}
                    handleBlockActions={handleBlockActions}
                    attackCd={(character?.equipment?.['main hand']?.speed ?? 2500)}
                  />
                }

                <Messages messages={messages}/>

                <div style={{...styles.charactersArea, left: 0}}>
                  <div className="text-center">
                    {Object.values(room.creatures).filter((cr) => cr.alignment === character.alignment).map(creature => (
                      <Character
                        key={creature._id}
                        character={creature}
                        isSelected={currentTarget.type === 'character' && currentTarget.id === creature._id}
                        isAggressive={character.aggroList.includes(creature._id)}
                        isInCombat={room.creatures[creature.aggroList[0]]?.name ?? null}
                        setTarget={() => {
                          setCurrentTarget({type: 'character', id: creature._id})
                        }}
                        moveAnimation={moveAnimations[creature._id]}
                        actionResults={actionResults.creature[creature._id] ?? []}
                        isAllies={true}
                      />
                    ))}
                  </div>
                  <div className="text-center">
                    {Object.values(room.items).filter((it) => it.lastAlignment === character.alignment).map((item) => (
                      <Item
                        key={item._id}
                        item={item}
                        isSelected={currentTarget.type === 'item' && currentTarget.id === item._id}
                        actions={{
                          ...(item.slot !== 'inventory' && item.slot !== 'immovable' && {equip: 'Equip'}),
                          ...(item.slot !== 'immovable' && {pick: 'Pick'}),
                          ...(item.use && {use: 'Use'})
                        }}
                        showItemModal={handleShowItemModal}
                        actionResults={actionResults.item[item._id] ?? []}
                      />
                    ))}
                  </div>
                </div>

                <div style={{...styles.charactersArea, right: 0}}>
                  <div className="text-center">
                    {Object.values(room.creatures).filter((cr) => cr.alignment !== character.alignment).map(creature => (
                      <Character
                        key={creature._id}
                        character={creature}
                        isSelected={currentTarget.type === 'character' && currentTarget.id === creature._id}
                        isAggressive={character.aggroList.includes(creature._id)}
                        isInCombat={room.creatures[creature.aggroList[0]]?.name ?? null}
                        setTarget={() => {
                          setCurrentTarget({type: 'character', id: creature._id})
                        }}
                        moveAnimation={moveAnimations[creature._id]}
                        actionResults={actionResults.creature[creature._id] ?? []}
                        isAllies={false}
                      />
                    ))}
                  </div>
                  <div className="text-center">
                    {Object.values(room.items).filter((it) => it.lastAlignment !== character.alignment).map((item) => (
                      <Item
                        key={item._id}
                        item={item}
                        isSelected={currentTarget.type === 'item' && currentTarget.id === item._id}
                        actions={{
                          ...(item.slot !== 'inventory' && item.slot !== 'immovable' && {equip: 'Equip'}),
                          ...(item.slot !== 'immovable' && {pick: 'Pick'}),
                          ...(item.use && {use: 'Use'})
                        }}
                        showItemModal={handleShowItemModal}
                        actionResults={actionResults.item[item._id] ?? []}
                      />
                    ))}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Col>

        <Col lg={2} className="my-3 my-lg-4">
          <h3>Map</h3>
        </Col>
      </Row>

      <ItemModal
        socket={socket}
        show={showItemModal}
        item={itemToShow.item}
        actions={itemToShow.actions}
        position={itemToShow.position}
        handleClose={handleHideItemModal}
        isActionsBlocked={isActionsBlocked}
        handleBlockActions={handleBlockActions}
      />

      <ScoutModal content={"test"} show={showScoutModal} handleClose={handleHideScoutModal} />
    </>
  )
}

const findCharacterAndRoom = (rooms, characterId) => {
  for (let i = 0; i < rooms.length; i++) {
    const room = rooms[i]
    const character = Object.values(room.creatures).find((cr) => cr._id === characterId)
    if (character) {
      return {room, character}
    }
  }
  return {}
}

const styles = {
  charactersArea: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
  },
  gameBg: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    filter: 'blur(3px)',
    opacity: 0.8,
  },
  gameContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
  gameArea: {
    position: 'relative',
    width: '100%',
  },
}

export default Game
