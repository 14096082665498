import Item from './Item'

const Inventory = ({inventory, showItemModal}) => {
  return (
    <>
      {inventory ? Object.values(inventory).map((item) => (
        <Item
          key={item._id}
          item={item}
          actions={{
            ...(item.slot !== 'inventory' && item.slot !== 'immovable' && {equip: 'Equip'}),
            drop: 'Drop',
            ...(item.use && {use: 'Use'})
          }}
          showItemModal={showItemModal}
        />
      )) : <small>empty</small>}
    </>
  )
}

export default Inventory
