import {Button} from 'react-bootstrap'
import {useRef} from 'react'

const Item = ({item, isSelected, actions, showItemModal, actionResults = []}) => {
  const ref = useRef()

  return <Button
    key={item._id}
    variant={qualityToClass(item.quality)}
    type="button"
    size="sm"
    className="d-inline-block me-1 mb-1 px-1 py-0 position-relative"
    onClick={() => showItemModal(item, actions, {
      left: ref.current.getBoundingClientRect().left,
      top: ref.current.getBoundingClientRect().top,
    })}
    style={{
      border: isSelected ? '2px solid rgb(101, 241, 255)' : '2px solid transparent',
    }}
    ref={ref}
  >
    {item.name + (item.stackable && item.quantity > 1 ? (' (' + item.quantity + ')') : '')}

    {actionResults.map((d) => <div
      key={`item-${d.key}`}
      className={`damage hit-${d.hitType}`}
      style={{left: `${d.left}%`}}>
      {d.value}
    </div>)}
  </Button>
}

const qualityToClass = (quality) => {
  if (quality === 0) {
    return 'light'
  } else if (quality === 1) {
    return 'warning'
  } else if (quality === 2) {
    return 'info'
  } else if (quality === 3) {
    return 'success'
  } else if (quality === 4) {
    return 'danger'
  }
}

export default Item
