import {Button, Card, Modal} from 'react-bootstrap'

const ScoutModal = ({content, show, handleClose}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdropClassName="transparent"
    >
      <Modal.Body className="p-0">
        <Card>
          <Card.Header className="position-relative px-2 py-1">
            Scouting - some room
            <Button
              variant="link"
              className="close-button"
              onClick={handleClose}
            >
              &times;
            </Button>
          </Card.Header>

          <Card.Body>
            <Card.Text className="mb-2">
              {content}
            </Card.Text>
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  )
}

export default ScoutModal
