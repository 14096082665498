import {Button, Image} from 'react-bootstrap'
import attack from '../../assets/images/attack.png'
import {useState} from 'react'

const Abilities = ({socket, target, isActionsBlocked, handleBlockActions, attackCd}) => {
  const [isAttackBlocked, setIsAttackBlocked] = useState(false)

  const onAttackClick = () => {
    setIsAttackBlocked(true)

    setTimeout(() => {
      setIsAttackBlocked(false)
    }, attackCd)

    socket.emit('attack', {target})
  }

  const onActionClick = () => {
    handleBlockActions()
    // todo
  }

  return (
    <div className="d-flex align-items-center justify-content-center" style={styles.abilities}>
      <Button
        variant="light"
        type="button"
        size="lg"
        onClick={() => onAttackClick()}
        disabled={isAttackBlocked}
        style={styles.ability}
      >
        <Image src={attack} width={30}/>
        <div
          className={'cooldown-overlay' + (isAttackBlocked ? ' start-animation' : '')}
          style={isAttackBlocked ? {animation: `cooldown-animation ${attackCd}ms linear forwards`}: {}}
        ></div>
      </Button>
    </div>
  )
}

const styles = {
  abilities: {
    position: 'absolute',
    bottom: 60,
    left: '50%',
    transform: 'translateX(-50%)',
    width: 200,
    height: 80,
    fontSize: '9px',
    textAlign: 'center',
    borderRadius: 10,
    opacity: 0.85,
    background: '#919995',
    zIndex: 1,
  },
  ability: {
    borderRadius: 8,
    height: 64,
    position: 'relative',
  },
}

export default Abilities
