import {Badge, Button, Card, Modal} from 'react-bootstrap'
import {getPrefix, getUnits, statToText} from '../../utils/stats'

const ItemModal = ({socket, show, item, actions, position, handleClose, isActionsBlocked, handleBlockActions}) => {
  const handleAction = (action) => {
    handleBlockActions()
    handleClose()
    socket.emit(action, {id: item._id, slot: item.slot})
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdropClassName="transparent"
      dialogClassName="custom-modal-position"
      style={{
        left: position.left,
        top: position.top,
      }}
    >
      <Modal.Body className="p-0">
        <Card
          style={{width: '18rem'}}
          border={qualityToBg(item.quality)}
          text="dark"
        >
          <Card.Header className="position-relative px-2 py-1">
            <sup>level {item.level}</sup> {item.slot}
            <Button
              variant="link"
              className="close-button"
              onClick={handleClose}
            >
              &times;
            </Button>
          </Card.Header>

          <Card.Body className={'p-2' + (item.type !== 'common' ? ' pt-0' : '')}>
            <Card.Text className="mb-2">
              {item.type !== 'common' && <span className="custom-list-item p-0"><sub>{item.type}</sub><br /></span>}

              <strong>{item.name + (item.stackable && item.quantity > 1 ? (` (${item.quantity})`) : '')}</strong>

              {qualityToBadge(item.quality)}

              {!item.stackable && item.use && item.quantity > -1 && <span className="custom-list-item p-0">
                  <br/>
                  <small>charges: {item.quantity}</small>
              </span>}

              {item.stats && Object.keys(item.stats).map((stat, i) => (
                <span key={stat} className="custom-list-item p-0">
                  <br/>
                  <small>{stat} <span className="float-end">
                    {getPrefix(stat)}{statToText(item.stats[stat])}{getUnits(stat)} {item.slot === 'main hand' && stat === 'damage' ? ` / ${Number.parseFloat((item.speed / 1000).toFixed(1))}s` : ''}
                  </span></small>
                </span>
              ))}
            </Card.Text>

            {actions && Object.keys(actions).map((action) => (
              <Button
                key={action}
                variant={action === 'drop' ? 'danger' : 'secondary'}
                className="me-1 mb-1"
                size="sm"
                onClick={() => {
                  handleAction(action)
                }}
                disabled={isActionsBlocked}
              >
                {actions[action]}
              </Button>
            ))}
            {item.effect && (
              <Button variant="success" className="me-1 mb-1 float-end" size="sm" onClick={handleClose}>Use</Button>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  )
}

const qualityToBg = (quality) => {
  if (quality === 0) {
    return 'light'
  } else if (quality === 1) {
    return 'warning'
  } else if (quality === 2) {
    return 'info'
  } else if (quality === 3) {
    return 'success'
  } else if (quality === 4) {
    return 'danger'
  }
}

const qualityToBadge = (quality) => {
  if (quality === 0) {
    return ''
  } else if (quality === 1) {
    return <Badge bg="warning" className="float-end">rare</Badge>
  } else if (quality === 2) {
    return <Badge bg="info" className="float-end">epic</Badge>
  } else if (quality === 3) {
    return <Badge bg="success" className="float-end">set</Badge>
  } else if (quality === 4) {
    return <Badge bg="danger" className="float-end">legendary</Badge>
  }
}

export default ItemModal
