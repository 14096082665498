import human from '../assets/images/human.webp';
import hobbit from '../assets/images/hobbit.webp';
import dwarf from '../assets/images/dwarf.webp';
import orc from '../assets/images/orc.webp';
import troll from '../assets/images/troll.webp';
import elf from '../assets/images/elf.webp';

import warrior from '../assets/images/warrior.webp'
import deathKnight from '../assets/images/dk.webp'
import mage from '../assets/images/mage.webp'
import wizard from '../assets/images/wizard.webp'
import priest from '../assets/images/priest.webp'
import paladin from '../assets/images/paladin.webp'
import hunter from '../assets/images/hunter.webp'
import rogue from '../assets/images/rogue.webp'
import necromancer from '../assets/images/necromancer.webp'
import druid from '../assets/images/druid.webp'

import good from '../assets/images/good.webp'
import evil from '../assets/images/evil.webp'
import neutral from '../assets/images/neutral.webp'

const alignmentAssets = {
  align0: good,
  align1: evil,
  align2: neutral
}
const raceAssets = {human, hobbit, orc, troll, dwarf, elf}
const classAssets = {
  warrior,
  'death knight': deathKnight,
  mage,
  wizard,
  priest,
  paladin,
  hunter,
  rogue,
  necromancer,
  druid
}

export {raceAssets, classAssets, alignmentAssets}
